.ant-form-item { margin-bottom: 7px !important; }
#myForm .ant-input { padding: 7px 11px !important; }
.my-form .ant-input { padding: 7px 11px !important; }
.hidden { display: none !important; }
.striking-logo img {
    max-height: 35px !important;
    width: unset !important;
    max-width: unset !important;
}
.ant-popover { z-index: unset !important; }
.table-responsive .ant-table-content .ant-table-cell { white-space: unset !important; }
.ant-card-body { padding: 15px !important; }
.ant-pagination { margin: 0 !important; }
.ant-table-pagination { padding-top: 15px !important; }
/* .anticon { vertical-align: unset !important; } */
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td { padding: 8px 16px !important; }
.ant-page-header {
    padding: 20px !important;
    border-radius: 10px !important;
    margin-top: 30px !important;
}
.login-form {
    margin-top: 10% !important;
    padding: 20px !important;
    background-color: #fff !important;
    border-radius: 10px !important;
}

.pl-5 {
    padding-left: 5px;
}
.pr-5 {
    padding-right: 5px;
}

.pt-5 {
    padding-top: 5px;
}

.pb-5 {
    padding-bottom: 5px;
}
